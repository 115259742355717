import { createContext } from 'react'

window.OCTOLY_DEVTOOL_ENABLED = false
export const DebugContext = createContext()

function DebugProvider({ children }) {
  return children
}

export default DebugProvider
